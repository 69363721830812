import { LogoutComponent } from './logout/logout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { ResetPasswordComponent } from './resetpassword/resetpassword.component';
import { ResetPassword2Component } from './resetpassword/resetpassword2.component';
import { Register2Component } from './register/register2.component';
import { ProtectedGuard } from './services/protected.guard';
import { NewsletterAbmeldenComponent } from './newsletter/newsletterabmelden.component';
import { VersionComponent } from './version/version.component';

export const routes: Routes = [
  { path: '', redirectTo: 'profil', pathMatch: 'full' },
  { path: 'login',  component: LoginComponent },
  { path: 'logout',  component: LogoutComponent },
  { path: 'profil', loadChildren: () => import('./profil/profil.module').then(m => m.ProfilModule), canActivate: [ProtectedGuard] },
  { path: 'register',  component: RegisterComponent },
  { path: 'register/:id',  component: Register2Component },
  { path: 'newsletter',  component: NewsletterComponent },
  { path: 'newsletter/abmelden',  component: NewsletterAbmeldenComponent },
  { path: 'resetpassword',  component: ResetPasswordComponent },
  { path: 'passwordreset/:id', component: ResetPassword2Component},
  { path: 'kursbuchen', loadChildren: () => import('./kursbuchen/kursbuchen.module').then(m => m.KursbuchenModule)},
  { path: 'veranstaltungen', loadChildren: () => import('./veranstaltungen/veranstaltungen.module').then(m => m.VeranstaltungenModule) },
  { path: 'mitgliedneu', loadChildren: () => import('./mitglied/mitglied.module').then(m => m.MitgliedModule) },
  { path: 'epayment', loadChildren: () => import('./epayment/epayment.module').then(m => m.EPaymentModule)},
  { path: 'version', component: VersionComponent},
  { path: '**', redirectTo: 'profil', pathMatch: 'full' },

];

// Debug Routing
const extraOptions: ExtraOptions = {};

@NgModule({
  imports: [RouterModule.forRoot(routes, extraOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
